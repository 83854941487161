import { createTheme } from "@mui/material"
import { pink, red } from '@mui/material/colors'

const theme = createTheme({
  palette: {
    primary: {
      main: pink[700],
    },
    background: {
      default: red[50],
    },
  },
  typography: {
    fontFamily:
      "Work Sans, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Oxygen, Cantarell, sans-serif",
    fontWeight: 500,
  },
})

theme.typography.h1 = {
  fontSize: "3rem",
  fontWeight: 700,
  lineHeight: 1,
  [theme.breakpoints.up("md")]: {
    fontSize: "4rem",
  },
}

export default theme