import { CssBaseline, ThemeProvider } from "@mui/material"
import React from "react"
import theme from "./src/theme"
import "@fontsource/work-sans/500.css"
import "@fontsource/work-sans/700.css"

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  )
}
